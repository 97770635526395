import { Result } from "../types";
import { config, log, supabase } from "../utils";
import { request, useSupabaseAPIClient } from "./helper";
import { resultSchema } from "../schemas";
import { useCallback } from "react";

export const insertSong = async (song: { id: string; userId: string; name: string; duration: number }) => {
  const { error } = await supabase.from("songs").insert([{ ...song }]);

  if (error) {
    throw error;
  }
};

export const dismissBlock = async (userId: string, key: string) => {
  const { error, status } = await supabase.from("dismissed_blocks").insert([{ userId, key }]);

  // Ignore 409 conflict
  if (error && status !== 409) {
    throw error;
  }
};

export const updateSong = async (
  id: string,
  song: { name?: string; secret?: string | null; status?: "active" | "archived" | "deleted", deletedAt?: string | null }
) => {
  const { error } = await supabase
    .from("songs")
    .update({ ...song })
    .eq("id", id);

  if (error) {
    throw error;
  }
};

export const updateChannel = async (
  id: string,
  channel: { label?: string; order?: number; status?: "active" | "archived" | "deleted", deletedAt?: string | null }
) => {
  const { error } = await supabase
    .from("channels")
    .update({ ...channel })
    .eq("id", id);

  if (error) {
    throw error;
  }
};

export const insertPlaylist = async (playlist: { id: string; userId: string; name: string }) => {
  const { error } = await supabase.from("playlists").insert([{ ...playlist }]);

  if (error) {
    throw error;
  }
};

export const updatePlaylist = async (
  id: string,
  playlist: { name?: string; secret?: string | null; status?: "active" | "archived" | "deleted", deletedAt?: string | null }
) => {
  const { error } = await supabase
    .from("playlists")
    .update({ ...playlist })
    .eq("id", id);

  if (error) {
    throw error;
  }
};

export const updatePlaylistOrder = async (playlistId: string, songIds: string[]) => {
  const records = songIds.map((songId, index) => {
    return { playlistId, songId, order: index + 1 };
  });

  const { error } = await supabase
    .from("playlists_songs")
    .upsert(records, { onConflict: "playlistId,songId" });

  if (error) {
    throw error;
  }
};

export const insertPlaylistSong = async (playlistSong: { order: number; playlistId: string; songId: string }) => {
  const { error } = await supabase
    .from("playlists_songs")
    .insert([{ ...playlistSong }]);

  if (error) {
    throw error;
  }
};

export const removePlaylistSong = async (playlistSong: { playlistId: string; songId: string }) => {
  const { error } = await supabase.from("playlists_songs").delete().match(playlistSong);

  if (error) {
    throw error;
  }
};

export const insertChannels = async (
  channels: {
    id: string;
    order: number;
    songId: string;
    label: string;
  }[]
) => {
  const { error } = await supabase.from("channels").insert(channels);

  if (error) {
    throw error;
  }
}

export const insertRegions = async (
  regions: {
    id: string;
    channelId: string;
    duration: number;
    position: number;
    key: string;
    filename: string;
    songId: string;
  }[]
) => {
  const { error } = await supabase.from("regions").insert(regions);

  if (error) {
    throw error;
  }
}

export const uploadRegionFile = async ({ key, file }: { key: string; file: File }) => {
  const { error } = await supabase.storage.from(config.bucket).upload(key, file, {
    contentType: file.type,
  });

  if (error) {
    throw error;
  }
};

export const updateProfile = async (
  id: string,
  profile: {
    name?: string;
    organization?: string | null;
    purposes?: string[];
    status?: "active" | "archived" | "deleted";
    deletedAt?: string | null;
  }
) => {
  const { error } = await supabase
    .from("profiles")
    .update({ ...profile })
    .eq("id", id);

  if (error) {
    throw error;
  }
};

export const createUser = async ({
  email,
  password,
  name,
  organization,
  purposes,
  origin,
  redirectTo,
}: {
  email: string;
  password: string;
  name: string;
  organization: string | null;
  purposes: string[];
  origin: string;
  redirectTo: string;
}): Promise<Result> => {
  const [data] = await request<Result>({
    path: "/api/createUser",
    method: "post",
    body: {
      email,
      password,
      name,
      organization,
      purposes,
      origin,
      redirectTo,
    },
    schema: resultSchema,
  });

  return data;
};

export const joinWaitlist = async ({ email }: { email: string }): Promise<Result> => {
  const [data] = await request<Result>({
    path: "/api/waitlist/join",
    method: "post",
    body: {
      email,
    },
    schema: resultSchema,
  });

  return data;
};

export const openSupportTicket = async ({
  userId,
  email,
  name,
  details,
}: {
  userId?: string;
  email: string;
  name: string;
  details: string;
}): Promise<Result> => {
  const [data] = await request<Result>({
    path: "/api/support",
    method: "post",
    body: {
      userId,
      email,
      name,
      details,
    },
    schema: resultSchema,
  });

  return data;
};

export const notifySupportTicket = async ({
  userId,
  email,
  name,
  details,
}: {
  userId?: string;
  email: string;
  name: string;
  details: string;
}) => {
  const [data] = await request({
    path: "https://maker.ifttt.com/trigger/support_ticket/json/with/key/b2h3aUWEJZdrODWN_ohZAwYjMBEYB37qSfILtzxg3Q6",
    method: "post",
    body: {
      userId,
      email,
      name,
      details,
    },
  });

  return data;
};

export const useIncrSongViewCount = () => {
  const supabaseSecretClient = useSupabaseAPIClient({ allowSecret: true })

  const incrSongViewCount = useCallback(async (id: string): Promise<number | null> => {
    let { data, error } = await supabaseSecretClient.rpc("incrSongViewCount", { id })

    if (error) {
      log("View count did not increase");
      return null;
    } else {
      log("View count updated to", data);
      return data as unknown as number;
    }
  }, [supabaseSecretClient])

  return incrSongViewCount;
}
